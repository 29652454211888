import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <header className='Banner'>
      <video autoPlay loop disablePictureInPicture muted width='100vw'>
        <source src="/videos/home.webm" type="video/webm"/>
      </video>
      <div>
        <h2>A ESCOLA<br/>
        QUE FLUI.</h2>
      </div>
    </header>
    )
  }
  
  export default Banner;
