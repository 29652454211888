import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../../../utils/responsiveness';
import { useCart } from '../../../hooks/useCart';
import { ReactComponent as CarBlack } from "../../../assets/images/car-black.svg";


const Navbar = ({ inverted, sendToBack, openCart, setOpenCart }) => {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classNav, setClassNav] = useState("");
  const { cart } = useCart();
  const cartSize = Object.keys(cart).length;

  const handleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      setClassNav("menu-closed");
    } else {
      setMenuOpen(true);
      setClassNav("menu-active");
    }
  };

  useEffect(() => {
    setClassNav('menu-closed')
  }, [pathname])

  return (
    <nav className={`Navbar ${(inverted && pathname === '/') || pathname === '/para-pessoas' || pathname === '/contato' || (pathname.includes("/para-pessoas/") && isMobile()) ? "inverted" : ""} ${sendToBack ? "back" : ""} ${classNav}`}>
      <a href={window.location.origin}>
        <h1>mastertech</h1>
      </a>
      <button>
        <div onClick={() => setOpenCart(!openCart)} className='cart'>
          <button>
            <p>{cartSize}</p>
            <CarBlack />
          </button>
        </div>
        <div aria-label={menuOpen ? "fechar menu" : "abrir menu"} onClick={handleMenu}>
          <span />
          <span />
          <span />
        </div>
      </button>
      <div className={pathname.split("/", 2)[1] === 'compra' && "hidden" || pathname === '/cart' && "hidden"}>
        <NavigationLink href='/' text='Home' active={pathname === '/'}>Home</NavigationLink>
        <NavigationLink href='/para-empresas' active={pathname.includes('/para-empresas')} highlight>Para empresas</NavigationLink>
        <NavigationLink href='/para-pessoas' active={pathname.includes('/para-pessoas')} highlight>Para pessoas</NavigationLink>
        <NavigationLink href='/solucoes' active={pathname.includes('/solucoes')}>Soluções</NavigationLink>
        <NavigationLink href='/contato' active={pathname.includes('/contato')}>Contato</NavigationLink>
        <div className='cart'>
          <button onClick={() => setOpenCart(!openCart)}>
            <p>{cartSize}</p>
            <CarBlack />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
