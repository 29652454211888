import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { msEndpoint } from '../../api/common';
import { ReactComponent as BackArrow } from '../../assets/images/arrow.svg';
import { ReactComponent as CarWhite } from "../../assets/images/car-white.svg";
import { ReactComponent as Decrement } from "../../assets/images/decrement.svg";
import { ReactComponent as Increment } from "../../assets/images/increment.svg";
import email from '../../assets/images/mail.png';
import phone from '../../assets/images/phone.png';
import whatsApp from '../../assets/images/whatsapp-logo.png';
import Button from '../../components/common/Button/Button';
import RotatingText from '../../components/common/RotatingText/RotatingText';
import { useCart } from '../../hooks/useCart';
import { getCourseDetails } from '../../redux/actions/courses';
import { FormatEnumTranslator } from '../../utils/enumTranslator';
import { formatPrice } from '../../utils/format';
import { replaceWithLineBreaks } from '../../utils/formating';
import { isMobile } from '../../utils/responsiveness';
import './B2CCourse.scss';

const B2CCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const course = useSelector(state => state.courses.courseDetail);
  const dispatch = useDispatch();
  const { addProduct, cart, updateProductAmount, removeProduct } = useCart();
  const [amountProduct, setAmountProduct] = useState(1)
  const [shoppingInfo, setShoppingInfo] = useState({})

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank').focus();
  };

  const handleShoppingCart = async (id) => {
    const response = await fetch(`${msEndpoint}/shopping/product/${id}`)
    const data = await response.json();
    setShoppingInfo(data)
  }

  useEffect(() => {
    let existsInCard = cart.find(product => product.id === shoppingInfo?.id)
    existsInCard ? setAmountProduct(existsInCard.amount) : setAmountProduct(1);
  }, [shoppingInfo, cart, updateProductAmount, removeProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCourseDetails(id)).then((json) => {
      handleShoppingCart(json.shoppingId);
    });
  }, [])

  function handleAddProduct(id) {
    addProduct(id, amountProduct)
  }

  return (
    <main className='B2CCourse'>
      <section className='course-info'>
        {!isMobile() && <BackArrow role="button" onClick={() => navigate('/para-pessoas')} />}
        <p className='format'>{course.category} {course.format}</p>
        <h2 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course.name) }}></h2>
        <div className='course-info-details'>
          {isMobile() && <img className='course-image' src={`/images/courses/b2c/${course.image}`} alt={course.alt} loading="lazy" />}
          <h3>PRÓXIMA TURMA</h3>
          <p className='next-date'>{course.nextClass}</p>
          <h3>DURAÇÃO</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course.estimateDuration) }}></p>
          <h3>PARA QUEM</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course.targetAudience) }}></p>
          <h3>FORMATO</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(FormatEnumTranslator(course?.format)) }}></p>
          <h3>INVESTIMENTO</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course.investment) }}></p>


          {window?.innerWidth < 1200 &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='product-details'>
                <div className='title-product'>
                  <p><span>INGRESSOS</span></p>
                  <div>
                    <CarWhite className='car-icon' />
                    <p>{formatPrice(shoppingInfo?.value / 100)}</p>
                  </div>
                </div>
                <div className='sub-details-product'>
                  <div className='infos-product'>
                    <p><strong dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(shoppingInfo.name) }}></strong></p>
                    <p>{formatPrice(shoppingInfo?.value / 100)}</p>
                    <p className='green'>em até 6x de {((shoppingInfo.value / 100) / 6)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                    <p>Vendas até {new Date(shoppingInfo?.stopSalesOn).toLocaleDateString()}</p>
                  </div>
                  <div className='increment-product'>
                    <div className='contain-product'>
                      <button
                        disabled={shoppingInfo?.supply < 1}
                        onClick={() => {
                          setAmountProduct(function (prevCount) {
                            if (prevCount > 1) {
                              return (prevCount -= 1);
                            } else {
                              return (prevCount = 1);
                            }
                          });
                        }}
                      >
                        <Decrement style={{ cursor: 'pointer' }} />
                      </button>
                      <p>{amountProduct}</p>
                      <button
                        disabled={shoppingInfo?.supply < 1}
                        onClick={() => {
                          amountProduct < shoppingInfo?.supply && setAmountProduct(amountProduct + 1)
                        }}
                        data-testid="decrement-product"
                      >
                        <Increment style={{ cursor: 'pointer' }} />
                      </button>
                    </div>
                  </div>
                </div>
                <button onClick={() => course?.shoppingId && handleAddProduct(course?.shoppingId)}
                  className='purchase-course-button'>COMPRAR INGRESSOS</button>
              </div>
            </div>
          }

        </div>
        {window?.innerWidth > 1200 && <article className='course-info-description'>
          {!isMobile() && <img className={`course-image ${course.name?.includes("PROGRAMAÇÃO") ? "ppnp" : ""}`} src={course.imageCourse?.url} alt={course.imageCourse?.alt} loading="lazy" />}
          <p className='description-course' dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course.long_description) }}></p>

          {window?.innerWidth > 1200 && course?.shoppingId && <div className='product-details'>
            <div className='title-product'>
              <p><span>INGRESSOS</span></p>
              <div>
                <CarWhite className='car-icon' />
                <p>{formatPrice(shoppingInfo?.value / 100)}</p>
              </div>
            </div>
            <div className='sub-details-product'>
              <div className='infos-product'>
                <p><strong dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(shoppingInfo.name) }}></strong></p>
                <p>{formatPrice(shoppingInfo?.value / 100)}</p>
                <p className='green'>em até 6x de {((shoppingInfo.value / 100) / 6)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                <p>Vendas até {new Date(shoppingInfo?.stopSalesOn).toLocaleDateString()}</p>
              </div>
              <div className='increment-product'>
                <div className='contain-product'>
                  <button
                    disabled={shoppingInfo?.supply < 1}
                    onClick={() => {
                      setAmountProduct(function (prevCount) {
                        if (prevCount > 1) {
                          return (prevCount -= 1);
                        } else {
                          return (prevCount = 1);
                        }
                      });
                    }}
                  >
                    <Decrement style={{ cursor: 'pointer' }} />
                  </button>
                  <p>{amountProduct}</p>
                  <button
                    disabled={shoppingInfo?.supply < 1}
                    onClick={() => {
                      amountProduct < shoppingInfo?.supply && setAmountProduct(amountProduct + 1)
                    }}
                    data-testid="decrement-product"
                  >
                    <Increment style={{ cursor: 'pointer' }} />
                  </button>
                </div>
              </div>
            </div>
            <button onClick={() => course?.shoppingId && handleAddProduct(course?.shoppingId)}
              className='purchase-course-button'>COMPRAR INGRESSOS</button>
          </div>}
        </article>}
        {!isMobile() && <RotatingText />}
      </section>
      <section className='course-details'>
        {isMobile() && <h3>O QUE VOCÊ APRENDERÁ</h3>}
        <div className='modules'>
          {course.modules?.map((module, index) => <details key={index}>
            <summary><p><strong>MÓDULO {index + 1}:</strong> {module.name}</p></summary>
            <p>{module.description}</p>
          </details>)}
        </div>
        <Button active onClick={() => course.shoppingId ? openLinkInNewTab(`/compra/${course.id}`) : openLinkInNewTab(course.externalUrl)} className='subscription'>{course?.nextClass === "" || course?.nextClass === "EM BREVE" ? "SAIBA-MAIS" : "INSCREVA-SE"}<BackArrow /></Button>
      </section>
      <section className='contact'>
        <h3>FICOU COM DÚVIDAS?</h3>
        <a href={`https://wa.me/5511919522455?text="Olá, gostaria de conhecer a formação de ${course.name}"`} rel='noopener noreferrer' target="_blank">mande mensagem <img src={whatsApp} alt='whatsapp' /></a>
        {!isMobile() && <a href={`"mailto:oi@mastertech.com.br?subject=informações de curso&body=OOlá, gostaria de conhecer a formação de ${course.name}`} rel='noopener noreferrer'>oi@mastertech.com.br <img src={email} alt='email' /></a>}
        {!isMobile() && <a href='tel:1191952-2455' rel='noopener noreferrer'>11 91952-2455 <img src={phone} alt='telefone' /></a>}
      </section>
    </main>
  )
}

export default B2CCourse;
