import React, { useEffect, useRef, useState } from 'react';
import About from '../../components/ui/About/About';
import Banner from '../../components/ui/Banner/Banner';
import Header from '../../components/ui/Header/Header';
import MDailyShowcase from '../../components/ui/MDailyShowcase/MDailyShowcase';
import Footer from '../../components/ui/Footer/Footer';
import './Home.scss';

const Home = ({setInvertedNavbar, setSendToBack}) => {
  const ref = useRef();
  const [scrolTop, setScrollTop] = useState(0)

  const changeBackground = () => {
    if (ref.current.scrollTop < window.innerHeight * 2.003) {
      setInvertedNavbar(true)
      setSendToBack(false)
    } else if (ref.current.scrollTop >= window.innerHeight * 2.36 && ref.current.scrollTop <= window.innerHeight * 3.71) {
      setInvertedNavbar(false)
      ref.current.scrollTop >= window.innerHeight + 175 ? setSendToBack(true) : setSendToBack(false)
    } else {
      setSendToBack(false)
      setInvertedNavbar(false)
    }
    setScrollTop(ref.current.scrollTop)
  }

  useEffect(() => {
    changeBackground()
    ref.current.addEventListener("scroll", changeBackground)
  })

  return (
    <div className="Home">
      <div className='scroll-snap-container' ref={ref}>
        <Banner/>
        <Header containerScrollTop={scrolTop}/>
        <MDailyShowcase/>
        <About/>
        <Footer/>
      </div>
    </div>
  )
}
  
export default Home;
