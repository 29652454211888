import React from 'react';
import { isMobile } from '../../../utils/responsiveness';
import './About.scss';

const About = () => {
  return (
    <section className='About'>
      <article className='title'>
          <h3>A ESCOLA<br/>QUE FLUI.</h3>
      </article>
      <article className='text'>
        <h4>MAIS DE 150<br />PROJETOS EM<br />4 ANOS</h4>
        <p>O ano é 2015 e respira-se tecnologia por todos os lugares. Apostamos na criação de cursos para a formação de profissionais de tecnologia. Tivemos bons momentos. Viramos modinha. Clientes corporativos importantes, logo no começo, foram uma abençoada anomalia. {isMobile() && <><br /><br /></>}Suportamos a fase inicial e passamos por momentos de arrogância. Repensamos o modelo de negócios. Mudamos as perguntas. O que realmente estávamos fazendo? O que gostaríamos de fazer? Era pelo dinheiro? Ou era pela educação e pela transformação? Não escolhemos o dinheiro. Não em primeiro lugar. O que importava não era mais o mercado. Importava fazer direito. Com essência. De verdade. Criamos musculatura em 2017 e 2018.</p>
        {isMobile() && <><br/><br/></>}
        <p>Entendemos a educação não apenas como uma prateleira de cursos. Criamos programas para professores. Lançamos projetos sociais. Fortalecemos o B2B, o nosso relacionamento com empresas. Criamos um laboratório de práticas ágeis e de pesquisas. Público, privado, empresa, indivíduo e família. E estamos bem. Três anos seguidos de crescimento. 30 colaboradores diretos. Autossustentável, 100% de capital próprio. Mais de 150 clientes corporativos em 4 anos. Referência em ensino de programação e transformação digital. Somos colecionadores de fracassos, sempre em busca do próximo. E entre um fracasso e outro, construímos alguns sucessos. Somos a Mastertech.</p>
      </article>
    </section>
  )
}
  
export default About;
