import React from "react";
import "./Footer.scss";
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from "../../../utils/responsiveness";

const Footer = ({hideOnHome}) => {
  const { pathname } = useLocation();

  return pathname !== "/para-pessoas" && (!hideOnHome && (pathname === "" || pathname === "/")) ? (
    <footer className="Footer">
      <p className="title">MASTERTECH </p>
      
      <p className="copyright">2022 Direitos reservados.</p>
      <div className="terms">
        <p>Leia nossos <Link to="/termos-condicoes">Termos de Uso</Link> {isMobile() && <><br/> <br/></>}e <Link to="/politica-de-privacidade">Política de Privacidade.</Link></p>
        <p>Se preferir, acesse o <Link to='/portaldotitular'>portal do titular</Link></p>
      </div>
          
      <div className="footer-contact-info">
        {!isMobile() && <p>acompanhe</p>}
        <a href="https://www.instagram.com/mastertech.tech/" rel="noopener noreferrer" target="_blank">
          <img src={require("../../../assets/images/instagram.png")} alt="instagram logo" />
        </a>
        <a href="https://www.linkedin.com/school/mastertech.tech/" rel="noopener noreferrer" target="_blank">
          <img src={require("../../../assets/images/linkedin.png")} alt="linkedin logo"/>
        </a>
      </div>
    </footer>
    ) : null
  }
  
  export default Footer;
