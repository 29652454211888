export const mdailys = [
  {
    link: "https://fabiovagnerribeiro.medium.com/orgulho-sem-preconceito-48e7e351cbcc",
    image: "/images/mdailys/mdaily-pride.png",
    alt: "montagem com pessoas e escrito pride",
    title: "ORGULHO SEM \n PRECONCEITO",
    description: "sobre transições econômicas e de costumes."
  },
  {
    link: "https://fabiovagnerribeiro.medium.com/um-fracasso-educativo-5335f9900686",
    image: "/images/mdailys/mdaily-ubereats.png",
    alt: "bolsa de moto do uber eats",
    title: "COMER OU SER \n COMIDO, EIS \n A QUESTÃO",
    description: "as reflexões que nascem (apenas?) de quedas barulhentas."
  },
  {
    link: "https://fabiovagnerribeiro.medium.com/dirija-como-almod%C3%B3var-60e43ee72a86",
    image: "/images/mdailys/mdaily-man.png",
    alt: "foto do almodovar",
    title: "DIRIJA COMO \n ALMODÓDVAR.",
    description: "sobre desaprender a desconfia.r"
  },
  {
    link: "https://fabiovagnerribeiro.medium.com/a-bola-%C3%A9-dela-97b18e3307d",
    image: "/images/mdailys/mdaily-women.png",
    alt: "foto da lucille ball",
    title: "A BOLA \n É DELA!",
    description: "uma leitura sobre a busca de espaço em condições adversas."
  },
  {
    link: "https://fabiovagnerribeiro.medium.com/veja-ben-32d79fabfa98",
    image: "/images/mdailys/mdaily-benjerrys.png",
    alt: "montagem com uma embalagem do ben and jerrys",
    title: "VEJA BEN!",
    description: "sobre assimetria de poderes na arena corporativa"
  },
  {
    link: "https://fabiovagnerribeiro.medium.com/os-gates-de-embarque-e-desembarque-37e6a2983184",
    image: "/images/mdailys/mdaily-killbill.png",
    alt: "kill bill escrito em preto sobre um fundo amarelo",
    title: "OS 'GATES' \n DE EMBARQUE \n E DESEMBARQUE",
    description: "a percepção de Bill Gates sobre NFT's e Bitcoins."
  }
];
