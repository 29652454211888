import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from '../../../utils/responsiveness';
import './Header.scss';

const Header = ({ containerScrollTop }) => {
  const windowHeight = window.innerHeight;
  const [hasBeenActive, setHasBeenActive] = useState(false);
  
  useEffect(() => {
    containerScrollTop >= (1.0001 * windowHeight) && containerScrollTop < (1.10 * windowHeight) && setHasBeenActive(true)
  }, [containerScrollTop])
  
  return (
    <header className='Header'>
      {isMobile()
        ? <h3>UMA ESCOLA DE<br />
          PENSAMENTO DIGITAL<br />
          E CORAÇÃO ANALÓGICO.</h3>
          : <h3 className={(containerScrollTop >= windowHeight * .7) && (containerScrollTop < 1.10 * windowHeight)  || hasBeenActive ? "active" : ""}>MASTERTECH, UMA ESCOLA<br />
          DE PENSAMENTO DIGITAL<br />
          E CORAÇÃO ANALÓGICO.</h3>}
      <p className={`subtitle ${containerScrollTop >= (1.0001 * windowHeight) && containerScrollTop < (1.10 * windowHeight) || hasBeenActive ? "active" : ""}`}>Reformular reflexões e forjar futuros para</p>
      <div className={containerScrollTop >= (1.6 * windowHeight) && containerScrollTop < (1.10 * windowHeight)  || hasBeenActive  ? "active" : ""}>
        <Link to="/para-empresas">empresas</Link>
        <p>e</p>
        <Link to="/para-pessoas">pessoas</Link>
      </div>
    </header>
    )
  }
  
  export default Header;
