import React, { useState } from "react";
import { apiLeadRegister } from "../../../api/lead";
import { ReactComponent as ArrowButton } from '../../../assets/images/arrow.svg';
import { useEmailField, usePhoneInput, useTextField } from "../../../hooks/formHooks";
import './ContactForm.scss';

const ContactForm = ({ inverted, setLoading, fee }) => {
  const [index, setIndex] = useState(0);
  const [name, setName, validName] = useTextField("");
  const [phone, setPhone, validPhone] = usePhoneInput("");
  const [email, setEmail, validEmail] = useEmailField("");
  const [company, setCompany, validCompany] = useTextField("");
  const [message, setMessage, validMessage] = useTextField("");
  const [displayError, setDisplayError] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const inputs = [
    {
      label: "Qual seu nome?",
      type: "text",
      placeholder: "nome",
      valid: validName,
      value: name,
      onChange: setName
    },
    {
      label: "Qual seu telefone?",
      type: "text",
      placeholder: "telefone",
      valid: validPhone,
      value: phone,
      onChange: setPhone
    },
    {
      label: "Qual seu email?",
      type: "email",
      placeholder: "email",
      valid: validEmail,
      value: email,
      onChange: setEmail
    },
    {
      label: "Qual a sua empresa?",
      type: "text",
      placeholder: "empresa",
      valid: validCompany,
      value: company,
      onChange: setCompany,
    },
    {
      label: "Digite sua mensagem?",
      type: "text",
      placeholder: "mensagem",
      valid: validMessage,
      value: message,
      onChange: setMessage
    }
  ];

  const next = () => {
    if (index < inputs.length - 1) {
      setDisplayError(false);
      setIndex(index + 1);
    }
  };

  const handleSubmit = () => {
    const payload = {
      name: name,
      company: company,
      email: email,
      telephone: phone,
      origin: "site",
      fields: {
        message: message,
      }
    };
    setLoading(true);
    apiLeadRegister(payload)
      .then(() => {
        setLoading(false);
        setFeedback(true);
        setFeedbackMessage("OBRIGADO, ENTRAREMOS EM CONTATO.");
        resetForm();
      })
      .catch(() => {
        setLoading(false);
        setFeedback(true);
        setFeedbackMessage("ERRO AO ENVIAR A MENSAGEM, ENTRE EM CONTATO COM (11)91952-2455.");
        resetForm();
      });
  };

  const resetForm = () => {
    setTimeout(() => {
      setIndex(0);
      setFeedback(false);
    }, 3000);

    setName("");
    setPhone("");
    setEmail("");
    setCompany("");
    setMessage("");
  };

  const keyPress = (event, valid, action) => {
    if (event.key === "Enter") {
      if (valid) {
        action();
      } else {
        setDisplayError(true);
      }
    }
  };

  return <div className={`ContactForm ${inverted ? "inverted" : ""}`}>
    <form action="" onSubmit={e => e.preventDefault()}>
      <label>
        {inputs[index].label}
        <div
          className="feedback-container"
          style={{ display: feedback ? "flex" : "none" }}>
          <p className="feedback">{feedbackMessage}</p>
        </div>
        <div>
          <input type={inputs[index].type}
            value={inputs[index].value}
            onChange={e => inputs[index].onChange(e.target.value)}
            placeholder={inputs[index].placeholder}
            onKeyUp={
              index < inputs.length - 1
                ? e => keyPress(e, inputs[index].valid, next)
                : e => keyPress(e, inputs[index].valid, handleSubmit)
            } /> <button onClick={index < inputs.length - 1
              ? () => next()
              : () => handleSubmit()}><ArrowButton /></button>
        </div>
      </label>
    </form>
    <div className='steps'>
      {inputs.map((input, key) => <p key={key} className={index === key ? "active" : ""} onClick={() => { setIndex(key) }}>{input.placeholder}</p>)}
    </div>
  </div>
}

export default ContactForm;
