import React, { useEffect, useState } from 'react';
import './MDailyShowcase.scss';
import { mdailys } from '../../../constants/mdailys';
import RotatingText from '../../common/RotatingText/RotatingText';
import { replaceWithLineBreaks } from '../../../utils/formating';
import { isMobile } from '../../../utils/responsiveness';

const MDailyShowcase = () => {
  const [visibleMdailys, setVisibleMdailys] = useState(mdailys)

  useEffect(() => {
    if (!isMobile()) {
      setVisibleMdailys(mdailys)
    } else {
      mdailys.splice(3, 3)
      setVisibleMdailys(mdailys);
    }
  }, [mdailys]);

  return (
    <section className='MDailyShowcase'>
      <article>
        <h3>Últimos<br />
          M/Dailys</h3>
        <RotatingText />
        <p>O M/Daily é iniciativa da Mastertech. Materiais e curadorias gratuitas sobre metodologias ágeis,<br />
          negócios e transformação digital, diariamente no seu WhatsApp.</p>
        <a className="subscribe" href="https://materiais.mastertech.com.br/mdaily-20202" target="_blank" rel="noopener noreferrer">ASSINE O M/DAILY</a>
      </article>
      <div className='mdaily-container'>
        {visibleMdailys?.map((item, key) => {
          return (
            <div key={key} className='mdaily' onClick={() => window.open(item.link, '_blank').focus()}>
              <img src={item.image} alt={item.alt} loading="lazy" />
              <div className='overlay-content'>
                <h4 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(item.title) }}></h4>
                <p>{item.description}</p>
                <a href={item.link} target="_blank">LER M/DAILY</a>
              </div>
            </div>)
        })}
      </div>
    </section>
  )
}

export default MDailyShowcase;
